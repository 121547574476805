var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.slide
    ? _c(
        "div",
        { staticClass: "service-info-slide-container" },
        [
          _c("slide-heading", { attrs: { "html-heading": _vm.slide.heading } }),
          _c("slide-image-and-description", {
            attrs: { slide: _vm.slide, "allow-images": true },
          }),
          _vm.serviceType === "business-domain"
            ? _c("domain-service-info", {
                attrs: { slide: _vm.slide },
                on: {
                  "next-slide": function ($event) {
                    return _vm.$emit("next-slide")
                  },
                },
              })
            : _vm.serviceType === "business-email"
            ? _c("email-service-info", {
                on: {
                  "next-slide": function ($event) {
                    return _vm.$emit("next-slide")
                  },
                },
              })
            : _vm.serviceType === "business-website-hosting"
            ? _c("web-hosting-info", {
                on: {
                  "next-slide": function ($event) {
                    return _vm.$emit("next-slide")
                  },
                },
              })
            : _c("div", [
                _c("p", [_vm._v("\n      Unable to load Service info\n    ")]),
              ]),
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center mt-2 mb-2" },
          [
            _c("ct-centered-spinner", {
              attrs: { variant: "primary", large: "" },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }