<template>
  <div v-if="loaded && slide" class="service-info-slide-container">
    <slide-heading :html-heading="slide.heading" />
    <slide-image-and-description :slide="slide" :allow-images="true" />

    <domain-service-info
      v-if="serviceType === 'business-domain'"
      :slide="slide"
      @next-slide="$emit('next-slide')"
    />

    <email-service-info
      v-else-if="serviceType === 'business-email'"
      @next-slide="$emit('next-slide')"
    />

    <web-hosting-info
      v-else-if="serviceType === 'business-website-hosting'"
      @next-slide="$emit('next-slide')"
    />

    <div v-else>
      <p>
        Unable to load Service info
      </p>
    </div>
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <ct-centered-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceInfoSlide',
  components: {
    CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
    DomainServiceInfo:        () => import('./services/DomainServiceInfo'),
    EmailServiceInfo:         () => import('./services/EmailServiceInfo'),
    WebHostingInfo:           () => import('./services/WebHostingInfo'),
    SlideImageAndDescription: () => import('@/components/StagelineV2/shared/SlideImageAndDescription.vue'),
    SlideHeading:             () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    serviceType() {
      return this.slide.layout_sub_type
    },
  },
  mounted() {
    this.loaded = true
  },
}
</script>
